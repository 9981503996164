// NOTE: jsを別ドメインで配信している場合にローカルと挙動が異なるためstaging等でbugsnag通知をテストしたい時用
export const bugsnagTest = () => {
  // SEE: https://github.com/bugsnag/bugsnag-js/blob/v7.16.6/packages/plugin-window-onerror/onerror.js
  const prevOnError = window.onerror

  // eslint-disable-next-line unicorn/prefer-add-event-listener
  window.onerror = (messageOrEvent, url, lineNo, charNo, error) => {
    // eslint-disable-next-line no-console
    console.log('onerror!', { messageOrEvent, url, lineNo, charNo, error })
    prevOnError && prevOnError.apply(window, [messageOrEvent, url, lineNo, charNo, error])
  }

  if (window.location.search === '?bugsnag-test') {
    // @ts-expect-error TS2304
    tttttttttttttttttt()
  }
}
